import { getLocalStorage } from '@shein/common-function'
import schttp from '@/public/src/services/schttp'

export async function getQsTimelinessInfo( addressInfoOther ) {
  const addressCookie = addressInfoOther || getLocalStorage('addressCookie')
  let addressInfo = {}
  
  // 优先取地址缓存的，拿不到再调查默认地址接口
  try {
    const addressJson = typeof addressCookie === 'string' ?  JSON.parse(addressCookie) : addressCookie
    if (addressJson?.countryId) {
      addressInfo = Object.assign({}, addressJson)
    } else {
      const defaultAddress = await schttp({
        url: '/api/cart/getDefaultAddress/get',
        method: 'GET',
      })
      addressInfo = Object.assign({}, defaultAddress?.info)
    }
  } catch (err) {
    console.log('[cart, getQsTimelinessInfo] 获取countryId失败: ' + err)
  }

  try {
    const qsTimelinessInfo = await schttp({
      url: '/api/common/qsTimelinessInfo/get',
      method: 'POST',
      data: { ...addressInfo }
    })
    return qsTimelinessInfo
  } catch (err) {
    console.log('[cart, getQsTimelinessInfo] 获取qs信息失败: ' + err)
    return {}
  }
}

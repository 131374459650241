import { onMounted } from 'vue'
import { getQsTimelinessInfo } from 'public/src/pages/cart_new/utils/qsTagInfo.js'
import { useMapState, useMapMutation as useMapMutations } from 'public/src/pages/store_pages/store/mapHook'

export const useQuickShipInit = () => {
  const { checkout, quickShipInfoTag } = useMapState([ 'checkout', 'quickShipInfoTag' ])
  const { assignState } = useMapMutations(['assignState'])

  const quickShip = async () => {
    const { OrderQuickshipStyle } = checkout.value.checkoutBFFAbtInfo   || {}
    const orderQuickshipStyleStatus = OrderQuickshipStyle?.param?.OrderQuickshipStyleStatus == 1
    if(!quickShipInfoTag.value?.qsDataProps && !quickShipInfoTag.value?.qsDataNoLabelProps){
      const result = await getQsTimelinessInfo( checkout.value.default_address )
      const qsDataProps = {
        isShowNewStyle: orderQuickshipStyleStatus, // 是否展示新样式，即带有icon + x 日达 | 老样式
        text: result.quickShipText, // qs标签标题（前半部分内容）
        noLableStyle: false, // 是否需要lable标签样式
        labelStyle: { margin: 0 },
        // qs标签时效信息（后半部分内容）1.新样式 2. isLocationTop = false 3.tip有值
        qsTimelinessInfo: {
          isLocationTop: true,
          tip: result?.info
        }
      }
      const qsDataNoLabelProps = { ...qsDataProps, noLableStyle: true }
      assignState({ quickShipInfoTag: {
        qsDataProps,
        qsDataNoLabelProps
      } })
    }
  }

  onMounted(() => {
    typeof window != 'undefined' && quickShip()
  })
  return {}
}

export const useGetGuickShip = () => {
  const { quickShipInfoTag } = useMapState([ 'quickShipInfoTag' ])
  return quickShipInfoTag
}
